import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { navigate } from "gatsby";
import * as Ant from "antd";
import Widget, { Font, Color, FontSize } from "./Widget";
import * as Icon from "./Icon";
import * as User from "../Contexts/UserContext";
import moment from "moment";
import _ from "lodash";
import { search as fuzzySearch, fuzzy } from "fast-fuzzy";

const UserRoleMap = {
  admin: "管理員",
  staff: "行銷人員",
};

const UserRoleIdMap = {
  1: "管理員",
  2: "行銷人員",
  3: "第三方行銷人員",
};

function FilterDropdownComponent(props) {
  const [searchValue, setSearchValue] = useState("");
  const [selected, setSelected] = useState([]);
  const {
    filters = [],
    selectedKeys = [],
    setSelectedKeys = () => {},
    confirm = () => {},
    clearFilters = () => {},
    visible,
  } = props;

  useEffect(() => {
    if (visible) {
      setSelected(selectedKeys);
    } else {
      setSearchValue("");
      setSelected([]);
    }
  }, [visible]);

  function handleChange(obj) {
    if (selected.includes(obj.value)) {
      const temp = selected.filter(v => v !== obj.value);
      setSelected(temp);
    } else {
      const temp = [...selected, obj.value];
      setSelected(temp);
    }
  }

  function onComfirm() {
    setSelectedKeys(selected);
    confirm();
  }

  const options = searchValue
    ? fuzzySearch(searchValue, filters, {
        keySelector: obj => obj.text,
      })
    : filters;

  return (
    <FilterDropdown>
      <Ant.Input
        placeholder="請輸入"
        size="small"
        value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
        style={{ width: "100%" }}
      />
      <Ant.Divider style={{ margin: "12px 0" }} />
      <div className="filter-scroll scrollbar-small">
        {options.length ? (
          <Ant.Space direction="vertical">
            {options.map(o => {
              return (
                <Ant.Checkbox
                  checked={selected.includes(o.value)}
                  onChange={() => handleChange(o)}
                >
                  {o.text}
                </Ant.Checkbox>
              );
            })}
          </Ant.Space>
        ) : (
          <Ant.Empty image={Ant.Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>
      {/* <hr /> */}
      <Ant.Divider style={{ margin: "12px 0" }} />
      <Ant.Space style={{ justifyContent: "space-between", width: "100%" }}>
        <Ant.Button type="text" onClick={clearFilters} size="small">
          重置
        </Ant.Button>
        <Ant.Button type="primary" onClick={onComfirm} size="small">
          套用
        </Ant.Button>
      </Ant.Space>
    </FilterDropdown>
  );
}

const UserList = ({ users, loading, ...props }) => {
  const { pageInfo = { current: 1, total: 0 }, 
          perPage = 10,
          filters = {
            userName: [],
            userCode: [],
            role: [],
            status: [],
            from: [],
          },
          onPageChange = () => {}, 
          onTableChange = () => {}} = props;

  users.map((item, index) => {
    item.sn = index + 1;
  });
  console.log(props, filters)
  const userFilterList = _.uniq([...filters.userName]).map(name => ({
    text: name === null ? "" : name,
    value: name === null ? "" : name,
  }));

  const userCodeFilterList = _.uniq([...filters.userCode]).map(
    UserCode => ({
      text: UserCode === null ? "" : UserCode,
      value: UserCode === null ? "" : UserCode,
    })
  );

  const roleFilterList = _.uniq([...filters.role.map(obj => obj.name)]).map(
    RoleName => {
      return {
        text: RoleName === null ? "" : RoleName,
        value:
          RoleName === null
            ? ""
            : filters.role.find(obj => obj.name === RoleName).id,
      };
    }
  );

  const statusFilterList = _.uniq([...filters.status]).map(
    Status => ({
      text: Status === 1 ? "啟用" : "停用",
      value: Status,
    })
  );

  const fromFilterList = _.uniq([...filters.from]).map(from => ({
    text: from === 0 ? "問卷平台" : "CRM行銷平台",
    value: from,
  }));

  const columns = [
    {
      title: "編號",
      key: "id",
      // render: (_, { id }, idx) => {
      //   return <Font.Body>{`${idx + 1}`}</Font.Body>;
      // },
      render: (_, { sn }) => {
        return <Font.Body>{`${sn}`}</Font.Body>;
      },
    },
    {
      title: "姓名",
      key: "name",
      filters: userFilterList,
      filterDropdown: FilterDropdownComponent,
      filterIcon: isFiltered => (
        <Icon.FilterList
          size={FontSize.title}
          color={isFiltered ? Color.Purple : Color.Black_25}
        />
      ),
      // onFilter: (value, record) => {
      //   return record?.name === value;
      // },
      render: (_, { name }) => {
        return <Font.Body>{name}</Font.Body>;
      },
    },
    {
      title: "使用者帳號",
      key: "username",
      filters: userCodeFilterList,
      filterDropdown: FilterDropdownComponent,
      filterIcon: isFiltered => (
        <Icon.FilterList
          size={FontSize.title}
          color={isFiltered ? Color.Purple : Color.Black_25}
        />
      ),
      // onFilter: (value, record) => {
      //   return record?.username === value;
      // },
      render: (_, { username }) => {
        return <Font.Body>{username}</Font.Body>;
      },
    },
    {
      title: "角色",
      key: "role",
      filters: roleFilterList,
      filterDropdown: FilterDropdownComponent,
      filterIcon: isFiltered => (
        <Icon.FilterList
          size={FontSize.title}
          color={isFiltered ? Color.Purple : Color.Black_25}
        />
      ),
      // onFilter: (value, record) => {
      //   return record?.RoleId === value;
      // },
      render: (_, { RoleId }) => {
        return (
          <StatusLabel style={{ backgroundColor: Color.LightPink }}>
            <Font.Body style={{ color: Color.GreyBlack }}>
              {UserRoleIdMap[RoleId]}
            </Font.Body>
          </StatusLabel>
        );
      },
    },
    // {
    //   title: "創建時間",
    //   key: "created",
    //   render: (_, { created }) => (
    //     <Font.Body style={{ color: Color.GreyBlack }}>
    //       {created ? moment(created).format("YYYY/MM/DD HH:mm:ss") : null}
    //     </Font.Body>
    //   ),
    // },
    {
      title: "最後登入時間",
      key: "updated",
      render: (_, { lastLoginAt }) => (
        <Font.Body style={{ color: "rgba(0,0,0,.45)" }}>
          {lastLoginAt
            ? moment(lastLoginAt).format("YYYY/MM/DD HH:mm:ss")
            : null}
        </Font.Body>
      ),
    },
    {
      title: "帳號狀態",
      key: "status",
      width: 150,
      filters: statusFilterList,
      filterDropdown: FilterDropdownComponent,
      filterIcon: isFiltered => (
        <Icon.FilterList
          size={FontSize.title}
          color={isFiltered ? Color.Purple : Color.Black_25}
        />
      ),
      onFilter: (value, record) => {
        return record?.status === value;
      },
      render: (_, record) => {
        const [text, color] = User.Utils.getUserStatusLabel(record);
        return (
          // <StatusLabel style={{ backgroundColor: color }}>
          //   <Font.Small style={{ color: Color.GreyBlack }}>
          //     {text || "-"}
          //   </Font.Small>
          // </StatusLabel>
          <UserStatusLabel>
            <DotIcon style={{ "background-color": color }}></DotIcon>
            <Font.Small style={{ color: color }}>{text}</Font.Small>
          </UserStatusLabel>
        );
      },
    },
    {
      title: "帳號來源",
      key: "from",
      filters: fromFilterList,
      filterDropdown: FilterDropdownComponent,
      filterIcon: isFiltered => (
        <Icon.FilterList
          size={FontSize.title}
          color={isFiltered ? Color.Purple : Color.Black_25}
        />
      ),
      onFilter: (value, record) => {
        return record?.from === value;
      },
      render: (_, record) => {
        const text = User.Utils.getFromLabel(record);
        return (
          <Font.Body style={{ color: "rgba(0, 0, 0, 0.65)" }}>
            {text || "-"}
          </Font.Body>
        );
      },
    },
    {
      title: () => <div style={{ color: Color.Purple }}>編輯</div>,
      key: "edit",
      width: 55,
      align: "center",
      render: (_, record) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            props.openEditModal(record);
          }}
        >
          <Icon.Edit size={FontSize.subTitle} color={Color.Purple} />
        </div>
      ),
    },
  ];

  return (
    <Wrapper>
      <Ant.Table
        dataSource={users}
        columns={columns}
        rowKey="userid"
        loading={loading}
        pagination={{
          showSizeChanger: false,
          current: pageInfo.current,
          total: pageInfo.total,
          pageSize: perPage,
          onChange: (p) => {
            onPageChange(p)
          },
        }}
        onChange={(selectedRowKeys, selectedRows, info) => {
          onTableChange(selectedRowKeys, selectedRows, info)
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .ant-table-pagination.ant-pagination {
    padding-top: 24px;
    padding-bottom: 0;
    margin: 0;
  }
  .ant-pagination-item {
    width: 32px;
    height: 32px;
    border-radius: 2px;
    border: solid 1px rgba(0, 0, 0, 0.15);
    a {
      background-color: #ffffff;
      color: rgba(0, 0, 0, 0.65);
      transition: all 0.25s linear;
    }
    &.ant-pagination-item-active a {
      background-color: #534ab3;
      color: #ffffff;
    }
  }
  /* & th.ant-table-cell {
    padding-top: 32px;
    padding-bottom: 4px;
    padding-left: 0;
    padding-right: 0;
    color: rgba(15, 14, 35, 0.65);
    font-weight: 400;

    .ant-table-filter-column {
      display: flex;
      margin: 0;
      width: 100%;
      align-items: center;
      .ant-table-filter-column-title,
      .ant-table-filter-trigger-container {
        padding: 0;
        flex: 0 auto;
        display: block;
        position: relative;
      }

      .ant-table-filter-trigger-container {
        margin-left: 7px;
        .ant-table-filter-trigger {
          width: 100%;
        }
      }
    }

    .ant-table-filter-column {
    } */

  /* .ant-table-cell {
    cursor: pointer;
  } */

  .ant-table {
    th {
      line-height: 1.57;
      padding: 4px 13px;
      border-bottom: 1px solid transparent;
      background-color: #fafafa;
      font-weight: 900;
      color: ${Color.Black_65};
      .ant-table-filter-column-title {
        color: ${Color.Black_65};
      }
      &.ant-table-cell {
        padding-top: 32px;
      }
    }
    td {
      padding: 6px 13px;
      line-height: 1.57;
      color: ${Color.Black_85};
    }
    th {
      .ant-table-filter-column {
        display: flex;
        margin: 0;
        width: 100%;
        align-items: center;
        .ant-table-filter-column-title,
        .ant-table-filter-trigger-container {
          padding: 0;
          flex: 0 auto;
          display: block;
          position: relative;
        }

        .ant-table-filter-trigger-container {
          margin-left: 7px;
          .ant-table-filter-trigger {
            width: 100%;
          }
        }
      }
    }

    td {
      padding: 16px 13px;
    }
  }
`;

const StatusLabel = styled.div`
  display: inline-block;
  border-radius: 18.5px;
  padding: 0px 11px;
  font-size: 13px;
  line-height: 22px;
  height: 22px;
`;
const UserStatusLabel = styled.div`
  display: flex;
  align-items: center;
`;
const FilterDropdown = styled.div`
  width: 180px;
  padding: 12px 6px 12px 12px;
  .filter-scroll {
    max-height: 250px;
    overflow: hidden;
    overflow-y: auto;
    /* margin-bottom: 12px; */
  }
`;

const DotIcon = styled.div`
  width: 8px;
  height: 8px;
  margin: 7px 8px 7px 0;
  border-radius: 5px;
  /* background-color: rgba(0, 0, 0, 0.45); */
  &.active {
    color: #389e0d;
    background-color: #389e0d;
  }
`;

export default UserList;
